// Externals
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { customerLogin, validateOTP, resendOTP, customerLoginCancel, clearErrorMessage, verifyRecaptcha, clearRecaptcha, clearResetRecaptcha } from '../../store/actions/auth';

//Selectors
import { dobHelpTextSelector, loginFailureMessageSelector, mobileHelpTextSelector, emailHelpTextSelector } from '../../store/selectors/brand';
import { emailAuthenticationFeatureSelector, recaptchaFeatureEnabledSelector, regionalisationSelector } from '../../store/selectors/config';

// Utils
import useHasChanged from '../../util/useHasChanged';
import useInterval from '../../util/useInterval';
import { clearInvalid, clearValid, markValid, markInvalid, handleNumericOnlyInput, dayAndMonthValidation, preventNonNumericInput } from '../../util/validate';

//Image
import { ReactComponent as NotificationBell } from '../../static/bell-icon.svg';

// Components
import OTPField from '../Forms/OTPField';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';
import NotificationPill from '../Widgets/NotificationPill.jsx';

// Styles
import './Login.scss';
import { recaptchaResetSelector, recaptchaResponseSelector } from '../../store/selectors/auth';

//Config
import config from '../../config';


// Login Form
const Login = () => {

	const dispatch = useDispatch();
	const region = useSelector(regionalisationSelector);
	const emailAuthEnabled = useSelector(emailAuthenticationFeatureSelector);
	const recaptchaEnabled = useSelector(recaptchaFeatureEnabledSelector);

	// ID Switcher
	const [ isEmailId, setIsEmailId ] = useState(false);

	// Mobile Number
	const [ mobileNumber, setMobileNumber ] = useState('');
	const [ mobileNumberValid, setIsValidMobileNumber] = useState(false);

	// Email Address
	const [ emailAddress, setEmailAddress ] = useState('');
	const [ emailAddressValid, setIsValidEmailAddress] = useState(false);

	// DOB
	const [ dob_dd, setDob_dd ] = useState('');
	const [ dob_mm, setDob_mm ] = useState('');
	const [ dob_yyyy, setDob_yyyy ] = useState('');

	// OTP
	const [ otp, setOtp ] = useState(['', '', '' , '', '' , '']);

	const mobileGroupRef = useRef(null);
	const mobileFieldRef = useRef(null);
	const emailGroupRef = useRef(null);
	const emailFieldRef = useRef(null);
	const dobGroupRef = useRef(null);
	const ddRef = useRef(null);
	const mmRef = useRef(null);
	const yyyyRef = useRef(null);
	const mobileContinueButtonRef = useRef(null);
	const otpContinueButtonRef =  useRef(null);
	const otpGroupRef = useRef(null);
	const [otp0, focusOtp0] = useFocus();
	const [otp1, focusOtp1] = useFocus();
	const [otp2, focusOtp2] = useFocus();
	const [otp3, focusOtp3] = useFocus();
	const [otp4, focusOtp4] = useFocus();
	const [otp5, focusOtp5] = useFocus();
	

	const otpReferences = useMemo( () => { return [otp0, otp1, otp2, otp3, otp4, otp5] }, [otp0, otp1, otp2, otp3, otp4, otp5]);
	const otpFocusFunctions = [focusOtp0, focusOtp1, focusOtp2, focusOtp3, focusOtp4, focusOtp5];


	// ReCAPTCHA
	const isSeleniumTest = config.ENVIRONMENT !== 'production' && window.location.href.indexOf('?isAutomated=1') > -1; // preventing recaptcha for selenium test
	const recaptchaRef = React.createRef();
	const recaptchaGroupRef = useRef(null);
	const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
	const siteKey = config.SITE_KEY;
	const loginAction = 'customerportal/Login';

	// resend OTP counter
	const [ otpCountdown, setOtpCountdown ] = useState(null);
	const [ otpInterval, setOtpInterval ] = useState(0);

	// new feature notification
	// const [newEmailAuth, setNewEmailAuth] = useState(() => {
	// 	const saved = localStorage.getItem('newEmailAuth');
	// 	return saved !== null ? JSON.parse(saved) : false;
	// });

	// setTimeout(() => {
	// 	localStorage.setItem('newEmailAuth', true);		
	// }, 5000);

	useInterval(() => {
		if (otpCountdown > 0) {
			setOtpCountdown(otpCountdown - 1);
		}
		else {
			setOtpInterval(0);
		}
	}, otpInterval);

	useEffect(() => {
		if (otpCountdown > 0) {
			setOtpInterval(1000);
		}
		else {
			setOtpInterval(0);
		}
	}, [ otpCountdown ]);

	const spinner = useSelector(state => state.auth.loading);
	const customerLoginId = useSelector(state => state.auth.customerLoginId);
	const showErrorMessage = useSelector(state => state.auth.showErrorMessage);
	const showTimeoutMessage = useSelector(state => state.auth.showTimeoutMessage);
	const showExpiredOtpMessage = useSelector(state => state.auth.showExpiredOtpMessage);
	const showErrorMessageHasChanged = useHasChanged(showErrorMessage);
	const errorMessage = useSelector(state => state.config.regionalisation.login.error.loginFailureMessage);
	const mobileHelpText = useSelector(mobileHelpTextSelector);
	const emailHelpText = useSelector(emailHelpTextSelector);
	const dobHelpText = useSelector(dobHelpTextSelector);
	const recaptchaResponse = useSelector(recaptchaResponseSelector);
	const recaptchaReset = useSelector(recaptchaResetSelector);
	const hideRecaptchaBadge = "<style> .grecaptcha-badge { display: none!important; }</style>";
	const customErrorMessage = useSelector(state => state.auth.customErrorMessage);
	const isAccountLocked = useSelector(state => state.auth.isAccountLocked);

	useEffect(()=>{
		if(!customerLoginId) return;
		if (otpCountdown === null) {
			focusOtp0();
		}
	}, [customerLoginId, focusOtp0, otpCountdown]);

	// scroll to top when OTP is prompted (for mobile)
	useEffect(() => {
		if (customerLoginId) {
			window.scrollTo({ top: 0, behaviour: 'smooth'});
		}
	}, [ customerLoginId ]);

	useEffect(() => {
		if(mobileNumber.length >= 10){
			validateMobileNumber(mobileNumber, true);
			dispatch(clearErrorMessage());
		}
	}, [mobileNumber])

	const setValue = (e) => {
		if (e.target.name === 'mobileNumber') {
			setMobileNumber(e.target.value);
		} 
		else if(e.target.name === 'emailAddress'){
			setEmailAddress(e.target.value);
		}
		else if (e.target.name === 'dob_dd') {
			setDob_dd(e.target.value);
		}
		else if (e.target.name === 'dob_mm') {
			setDob_mm(e.target.value);
		}
		else if (e.target.name === 'dob_yyyy') {
			setDob_yyyy(e.target.value);
		}
		else if (e.target.name === 'otp') {
			setOtp(e.target.value);
		}
	};

	const handleKeyDown = (e) => {
		if(e.target.name === 'emailAddress'){
			dispatch(clearErrorMessage());
			return;
		}

		if(e.key !== '-' || e.target.name === 'dob_dd' || e.target.name === 'dob_mm' || e.target.name === 'dob_yyyy'){
			preventNonNumericInput(e);
		}

		const newValue = handleNumericOnlyInput(e);

		if(e.target.name === 'mobileNumber'){
			if(showErrorMessage || showExpiredOtpMessage)
				dispatch(clearErrorMessage());
			validateMobileNumber(newValue, true);
		}

		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm' || e.target.name === 'dob_yyyy'){
			if(showErrorMessage || showExpiredOtpMessage)
				dispatch(clearErrorMessage());
			validateDob(newValue, e.target.name);
		}
		
	}

	const handleKeyUp = (e) => {
		if(e.which === 13 && mobileContinueButtonRef.current){
			var mobileValid = validateMobileNumber(mobileNumber, false);
			var emailValid = validateEmailAddress(emailAddress, false);
			var dobValid = validateDob(dob_yyyy, 'dob_yyyy');

			if((isEmailId && !emailValid) || (!isEmailId && !mobileValid) || !dobValid){				
				handleDobFocus(e.target.name, emailValid, mobileValid);				
			}
			else 
			{			
				mobileContinueButtonRef.current.click();
				return;
			}
			
		}

		if(e.target.name === 'emailAddress'){
			if(showErrorMessage)
				dispatch(clearErrorMessage());
			validateEmailAddress(e.target.value, true);
		}
	}

	const handleDobFocus = (name, emailValid, mobileValid) => {
		if(region.login?.dob?.fieldOrder == null) return;
		var order = region.login.dob.fieldOrder;
		if(order.length == 0) return;	
		if((name === 'emailAddress' && !emailValid) || (name === 'mobileNumber' && !mobileValid)){
			return;
		}

		var index = 0
		
		var tempIndex = order.findIndex((field) => field === name.replace("dob_", ""));
		if(tempIndex !== -1 && tempIndex < order.length - 1){
			index = tempIndex + 1;
		}

		getDobRef(order[index]).current.focus();
	}

	const getDobRef = (id) => {
		switch(id){
			case 'dd':
				return ddRef;
			case 'mm':
				return mmRef;
			case 'yyyy':
				return yyyyRef;
			default:
				return null;
		}
	}

	const getDobMaxLength = (id) => {
		switch(id){
			case 'dd':
				return 2;
			case 'mm':
				return 2;
			case 'yyyy':
				return 4;
			default:
				return null;
		}
	}

	const handleOnBlur = (e) => {
		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm'){
			if(e.target.value.length === 1) {
				if(e.target.name === 'dob_dd' && e.target.value !== '0'){
					setDob_dd('0' + e.target.value);
				} else if(e.target.name === 'dob_mm' && e.target.value !== '0'){
					setDob_mm('0' + e.target.value);
				}
			}
		} else if(e.target.name === 'dob_yyyy'){
			if(dobGroupRef.current && !e.target.checkValidity()){
				markInvalid(dobGroupRef)
			}
		}
	}

	const handleOTPChange = (index) => (e) => {
		const newOtpVal = otp;
		newOtpVal[index] = e.target.value;
		setOtp(newOtpVal);
		if(e.target.value.length === 1 && index !== 5){
			otpFocusFunctions[index + 1]();
		}
	}

	const handleOTPKeyDown = (index) => (e) =>{
		// Intercept Ctrl + V key down - allow pasting of OTP, requires Clipboard access permission in chrome.
		// if (e.ctrlKey && e.key === 'v') {
		// 	if (navigator.clipboard && navigator.clipboard.readText) {
		// 		navigator.clipboard.readText().then(text => {
		// 			console.log("read clipboard");
		// 			if (/^\d+$/.test(text.trim())) {
		// 				const otpArray = text.trim().split('').slice(0, 6);
		// 				setOtp(otpArray);
		// 				console.log(otpArray);
		// 				otpArray.forEach((val, idx) => {
		// 					if (otpReferences[idx].current) {
		// 						otpReferences[idx].current.value = val;
		// 					}
		// 				});
		// 			}		
		// 		});
		// 	}
		// 	return;
		// }

		if(showErrorMessage || showExpiredOtpMessage){
			dispatch(clearErrorMessage());
		}

		preventNonNumericInput(e);

		if(e.target.value.length === 0 && e.which === 8 && index !== 0){
			otpFocusFunctions[index - 1]();
		}

		if(e.which === 13 && otpContinueButtonRef.current){
			otpContinueButtonRef.current.click();
		}

	}

	const handleDobKeyUp = (e) => {
		var exemptModifiers = ['Tab', 'Shift', 'Alt', 'Control', 'CapsLock', 'Escape', 'Delete', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];
		var firstField = region.login?.dob?.fieldOrder[0];
		var secondField = region.login?.dob?.fieldOrder[1];
		var thirdField = region.login?.dob?.fieldOrder[2];
		if(firstField == null || secondField == null || thirdField == null) return;
		
		var secondRef = getDobRef(secondField);
		var thirdRef = getDobRef(thirdField);

		if(e.target.name.includes(firstField) 
			&& e.target.value.length === getDobMaxLength(firstField) 
			&& secondRef.current 
			&& !exemptModifiers.includes(e.key)){
				secondRef.current.focus();
		} else if (e.target.name.includes(secondField)
			&& e.target.value.length === getDobMaxLength(secondField) 
			&& thirdRef.current 
			&& !exemptModifiers.includes(e.key)){
			thirdRef.current.focus();
		}

		handleKeyUp(e);
	}

	const handleOTPKeyUp = (e) => {
		validateOtp(false);
	}

	const handleLogin = () => {
		if(!isEmailId && !validateMobileNumber(mobileNumber, false))
			return
		
		if(isEmailId && !validateEmailAddress(emailAddress, false))
			return

		if(!validateDob(dob_dd, 'dob_dd', true))
			return

		if(recaptchaEnabled && !validateRecaptcha())
			return

		const args = {
			...(!isEmailId && {mobileNumber: mobileNumber.replace(/-/g, '')}),
			...(isEmailId && {emailAddress: emailAddress}),
			dob: dob_yyyy + '-' + dob_mm + '-' + dob_dd
		};

		dispatch(customerLogin(args));
	}

	const handleLoginOTP = () => {
		if(validateOtp(true)){
			dispatch(validateOTP(otp.join('')));
		}
	}

	const handleResendOTP = () => {
		dispatch(resendOTP());
		setOtpCountdown(30);
	}

	const validateEmailAddress = (value, didChange) => {
		const emailRegex = region.email.pattern;
		if(new RegExp(emailRegex).test(value)){
			if(!showErrorMessage){
				markValid(emailGroupRef);
				clearInvalid(emailGroupRef);
			} else {
				clearValid(emailFieldRef);
			}
			if(!emailAddressValid){
				setIsValidEmailAddress(true);
			}
			
			if(!didChange) {
				return true;
			}
		} else {
			clearValid(emailGroupRef);
			markInvalid(emailGroupRef);
			if(!didChange){
				markInvalid(emailGroupRef);
				return false;
			}
		}
	}

	const validateMobileNumber = (value, didChange) => {
		const trimmedValue = value.replace(/-/g, '');
		const mobileRegex = region.phone.mobile.pattern;
		if(new RegExp(mobileRegex).test(trimmedValue)){
			if(!showErrorMessage){
				markValid(mobileGroupRef);
				clearInvalid(mobileGroupRef);
			} else {
				clearValid(mobileFieldRef);
			}
			if(!mobileNumberValid){				
				setIsValidMobileNumber(true);
			}
			
			if(!didChange) {
				return true;
			}
		} else {
			clearValid(mobileGroupRef);			
			if(!didChange){
				markInvalid(mobileGroupRef);
				return false;
			}
		}
		
	}

	const validateDob = (value, target, isSubmit) =>{
		var isValid = null;
		if((ddRef.current && ddRef.current.checkValidity()) || (target === 'dob_dd' && value)){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
	
			//Handles date being out of bounds i.e. < 1 or > 31
			if(dd !== 0 || isSubmit){
				isValid = dayAndMonthValidation(dd, null, null);
			}

			if(isValid == null && 
				((mmRef.current && mmRef.current.checkValidity()) || (target === 'dob_mm' && value)) &&
				((yyyyRef.current && yyyyRef.current.checkValidity()) || 
					(target === 'dob_yyyy' && value.length === 4) || isSubmit)){
				const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
				const yyyy = target === 'dob_yyyy' ? parseInt(value) : parseInt(dob_yyyy);
				
				if(mm !== 0 || isSubmit){			
					isValid = dayAndMonthValidation(dd, mm, yyyy);
				}
			}
			
		}

		if((isValid === null) && ((ddRef.current && ddRef.current.checkValidity()) || (target === 'dob_dd' && value))){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
			const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
			if(dd !== 0 && mm !== 0 && !isSubmit){
				isValid = dayAndMonthValidation(dd, mm, null);	
			}
		}

		if(((ddRef.current && ddRef.current.value.length < 2) || (mmRef.current && mmRef.current.value.length < 2) || (yyyyRef.current && yyyyRef.current.value.length < 4)) && isSubmit){
			isValid = false;
		}

		if(dobGroupRef.current){
			if(isValid === null) {
				clearValid(dobGroupRef);
				clearInvalid(dobGroupRef);
			} else if(!isValid){
				clearValid(dobGroupRef);
				markInvalid(dobGroupRef);
			}else{
				clearInvalid(dobGroupRef);
				markValid(dobGroupRef);
			}
		}

		return isValid;
	};

	const validateRecaptcha = useCallback(() => {
		if(isSeleniumTest){
			return true;
		}
		// Check if recaptcha was loaded
		if (recaptchaLoaded) {
			// Clear validation messages
			clearValid(recaptchaGroupRef);
			clearInvalid(recaptchaGroupRef);

			// return true if user succeeded recaptcha
			if (recaptchaResponse !== null && recaptchaResponse.success) {
				return true;
			}
		} else {
			// Return true as a fail-safe if recaptcha wasn't able to load
			// Customer should still be able to proceed to login even if recaptcha didn't load
			return true;
		}

		// Show validation if user failed recaptcha/ did not do recaptcha, as long as recaptcha checkbox was able to load
		if (recaptchaGroupRef.current) {
			markInvalid(recaptchaGroupRef);
		}

		return false;
	});

	const recaptchaOnLoad = useCallback(() => {
		setRecaptchaLoaded(true);
	});

	const recaptchaOnChangeCallback = useCallback((value) => {
		if(!recaptchaLoaded) {
			return;
		}

		if ((value !== null || value !== undefined) && ((recaptchaResponse === null || recaptchaResponse === undefined) || (recaptchaResponse && !recaptchaResponse.success)) ) {
			const params = {
				token: value,
				sitekey: siteKey,
				action: loginAction 
			}
			dispatch(verifyRecaptcha(params));
			dispatch(clearRecaptcha());

			// clear validation message as soon as recaptcha is confirmed
			clearInvalid(recaptchaGroupRef);
		}
	}, [dispatch, siteKey, recaptchaResponse, recaptchaLoaded]);

	const recaptchaOnExpiredCallback = useCallback(() => {
		dispatch(clearRecaptcha());
	}, [dispatch]);

	useEffect(() => {
		if(recaptchaReset && recaptchaRef && recaptchaRef.current){
			recaptchaRef.current.reset();
			dispatch(clearResetRecaptcha());
		}
	}, [dispatch, recaptchaReset, recaptchaRef]);

	const isLoginValidated = () => {
		if(isEmailId)
			return emailAddressValid;

		return mobileNumberValid;
	}

	const validateOtp = useCallback((isSubmit) => {
		if(otpGroupRef.current && otp0.current && otp1.current && otp2.current && otp3.current &&
			otp4.current && otp5.current){
			if(!showErrorMessage && 
				!isSubmit &&
				otp0.current.value.length === 1 &&
				otp1.current.value.length === 1 &&
				otp2.current.value.length === 1 && 
				otp3.current.value.length === 1 &&
				otp4.current.value.length === 1 &&
				otp5.current.value.length === 1) {
					clearInvalid(otpGroupRef);
					markValid(otpGroupRef);

			} else if(isSubmit && (!otp0.current.checkValidity() || !otp1.current.checkValidity() || !otp2.current.checkValidity() || !otp3.current.checkValidity() ||
				!otp4.current.checkValidity() || !otp5.current.checkValidity())){
					clearValid(otpGroupRef);
					markInvalid(otpGroupRef);
					return false;
			} else if(isSubmit) {
				clearInvalid(otpGroupRef);
				markValid(otpGroupRef);
				return true;
			} else {
				clearInvalid(otpGroupRef);
				clearValid(otpGroupRef);
			}
		}

		return true;
	}, [otp0, otp1, otp2, otp3, otp4, otp5, otpGroupRef, showErrorMessage])

	useEffect(() => {
		if(!customerLoginId) return;
		if((showErrorMessageHasChanged && showErrorMessage) || (showExpiredOtpMessage)){
			otpReferences.forEach(ref => {
				if(ref.current){
					ref.current.value = '';
				}
			});
			setOtp(['', '', '', '', '', '']);
			validateOtp();	
			focusOtp0();
		}
	}, [showErrorMessage, showErrorMessageHasChanged, otpReferences, showExpiredOtpMessage, validateOtp, focusOtp0]);

	useEffect(() => {
		if(showErrorMessage){
			clearValid(mobileGroupRef);
			clearValid(emailGroupRef);
			clearValid(dobGroupRef);
			clearValid(otpGroupRef);
		}
	}, [showErrorMessage])

	const handleCancel = useCallback(() => {
		setMobileNumber('');
		setEmailAddress('');
		setIsValidMobileNumber(false);
		setIsValidEmailAddress(false);
		setDob_dd('');
		setDob_mm('');
		setDob_yyyy('');
		dispatch(customerLoginCancel());
	}, [setMobileNumber, setIsValidMobileNumber, setEmailAddress, setIsValidEmailAddress, setDob_dd, setDob_mm, setDob_yyyy, dispatch]);

	useEffect(()=> {
		if(showExpiredOtpMessage && customerLoginId){
			handleCancel();
		}
	}, [showExpiredOtpMessage, customerLoginId, handleCancel])

	//Date of Birth fields 
	const dobFields = {
		yyyy: <><label htmlFor="dob_yyyy" hidden>Year of Birth</label><input ref={yyyyRef} key="yyyy" type='text' inputMode="numeric" id='dob_yyyy' name='dob_yyyy' aria-label='Year of Birth' value={dob_yyyy} placeholder='YYYY' maxLength={4} pattern='\d{4}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required /></>,
		mm: <><label htmlFor="dob_mm" hidden>Month of Birth</label><input ref={mmRef} key="mm" type='text' inputMode="numeric" id='dob_mm' name='dob_mm' value={dob_mm} aria-label='Month of Birth' placeholder='MM' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required /></>,
		dd: <><label htmlFor="dob_dd" hidden>Day of Birth</label><input ref={ddRef} key="dd" type='text' inputMode="numeric" id='dob_dd' name='dob_dd' value={dob_dd} aria-label='Day of Birth' placeholder='DD' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required /></>														
	}


	// Render
	return (
		<div className='form form-login'>
			{region && region.login && region.login.mobile && region.login.email && <>
				<div aria-live="polite">
					{ showErrorMessage && !customErrorMessage && <p className='error' dangerouslySetInnerHTML={{ __html: errorMessage }}></p> }				
					{ (!showErrorMessage && showTimeoutMessage) && <p className='error'>Your session has expired.  Please login again to continue.</p> }
					{ showExpiredOtpMessage && <p className='error'>OTP expired. Please try again.</p>}
					{ customErrorMessage && <p className='error' dangerouslySetInnerHTML={{ __html: customErrorMessage }}></p> }
				</div>
				{ customerLoginId && recaptchaEnabled && <div dangerouslySetInnerHTML={{ __html: hideRecaptchaBadge }}></div>}
				{ !customerLoginId && !isAccountLocked && (<>
					{emailAuthEnabled && 
						<div className="id-container">
							<div className="id-option">
							<input type="radio" id="mobile" name="id" value="mobile" aria-label='SMS' checked={!isEmailId} onChange={(e)=> setIsEmailId(!e.target.checked)}/> 
							<label htmlFor="mobile">SMS</label></div>
							<div className="id-option">
							<input type="radio" id="email" name="id" value="email" aria-label='Email' checked={isEmailId} onChange={(e)=> setIsEmailId(e.target.checked)} /> 
							<label htmlFor="email">Email</label> 
							{config.DEFAULT_COUNTRY_ID == 13 && 
								<NotificationPill lsKey="newEmailAuth">
									<NotificationBell />
									<span>New!</span>
								</NotificationPill>
							}
							</div>
						</div>
					}
					{!isEmailId && 
						<div ref={mobileGroupRef} className='field field-login-mobileNumber'>
							<div className='mobile-label label-container'>
								<label htmlFor='mobileNumber'>{region.login.mobile.label}</label>
								<div className='info-icon'></div>
								<div className='tooltip' dangerouslySetInnerHTML={ { __html: mobileHelpText}}></div>
							</div>

							<input 
								ref={mobileFieldRef} 
								type='tel' 
								inputMode="numeric" 
								name='mobileNumber' 
								value={mobileNumber} 
								placeholder={region.login.mobile.placeholder} 
								maxLength={region.login.mobile.maxLength}								
								onChange={setValue} 
								onKeyDown={handleKeyDown} 
								onKeyUp={handleKeyUp} 
								aria-label={region.login.mobile.label}
								required 
							/>
							<div aria-live="polite">
								<span className='invalid-message'>{region.login.mobile.invalidMessage}</span>
							</div>
						</div>
					}

					{isEmailId && 
						<div ref={emailGroupRef} className='field field-login-emailAddress'>
							<div className='email-label label-container'>
								<label htmlFor='emailAddress'>{region.login.email.label}</label>
								<div className='info-icon'></div>
								<div className='tooltip' dangerouslySetInnerHTML={ { __html: emailHelpText}}></div>
							</div>

							<input ref={emailFieldRef} type='email' name='emailAddress' value={emailAddress} placeholder={region.login.email.placeholder} aria-label={region.login.email.label} onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} required />
							<div aria-live="polite">
								<span className='invalid-message'>{region.login.email.invalidMessage}</span>
							</div>
						</div>
					}

					{((!isEmailId && mobileNumberValid) || (isEmailId && emailAddressValid)) &&
					
						<div ref={dobGroupRef} className='field field-login-dob'>
							<div className='dob-label label-container'>
								<label htmlFor='dob'>{region.login.dob.label}</label>
								<div className='info-icon'></div>
								<div className='tooltip' dangerouslySetInnerHTML={ { __html: dobHelpText}}></div>
							</div>
							
							<fieldset id='dob' name="dob" legend="Date of Birth" className='dob-fields'>
								{region.login.dob.fieldOrder.map(field => {
									return dobFields[field];
								})}								
							</fieldset>
							<div aria-live="polite">
								<span className='invalid-message'>{region.login.dob.invalidMessage}<br/>{region.login.dob.example}</span>
							</div>
						</div>

					}
					

					{((!isEmailId && mobileNumberValid) || (isEmailId && emailAddressValid)) && recaptchaEnabled && !isSeleniumTest &&
						<div ref={recaptchaGroupRef} className="field field-login-recaptcha">
							<ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={recaptchaOnChangeCallback} onExpired={recaptchaOnExpiredCallback} asyncScriptOnLoad={recaptchaOnLoad} />
							<div aria-live="polite"><span className='invalid-message'>Please verify that you are not a robot</span></div>
						</div>
					}

					<button ref={mobileContinueButtonRef} className='form-btn' onClick={(e) => { !isLoginValidated() ?  isEmailId ? validateEmailAddress(emailAddress, false) : validateMobileNumber(mobileNumber, false) : handleLogin()}}><>{!isLoginValidated() ? 'Continue' : 'Log In'}</><span className='chevron-r'/></button>

				</>)}

				
				{customerLoginId && !isAccountLocked && (<>

					<div className='field-login-otp'>
						{!isEmailId &&
							<label htmlFor='otp'>{region.login.mobile.otp.label}
								<div className='mobileNumber'><b>{ region.login.mobile.otp.mask + mobileNumber.replace(/-/g, '').substring(7, 10)}</b></div>
							</label>
						}
						{isEmailId && 
							<label htmlFor='otp'>{region.login.email.otp.label}
								<div className='mobileNumber'><b>{ emailAddress.toLowerCase().substring(0, 4) + "*".repeat(emailAddress.split("@")[0].length - 3) + "@*****"}</b></div>
							</label>
						}
						

						<div ref={otpGroupRef} className='field form-login-otp'>
							<div className='otp-field-container'>
								{otp.map((value, index) =>  {									
									var ariaLabel = (index === 0 ? "Please enter the verification code Digit " : "Digit ") + (index + 1);
									return <OTPField key={index} index={index} value={value} handleOTPChange={handleOTPChange} handleOTPKeyDown={handleOTPKeyDown} handleOTPKeyUp={handleOTPKeyUp} reference={otpReferences[index]} ariaLabel={ariaLabel} />
								})}
							</div>
							<div aria-live="polite">
							{isEmailId ?
								<span className='invalid-message'>{region.login.email.otp.invalidMessage}</span> :
								<span className='invalid-message'>{region.login.mobile.otp.invalidMessage}</span>
							}
							</div>
						</div>
						
						<div className='resend-container'>
							<p>Need another verification code? </p>
							{(otpCountdown === 0 || otpCountdown === null) && <>&nbsp;<a href="#0" className='resend-link' onClick={handleResendOTP}>Resend</a></>}
							{otpCountdown > 0 && <span>&nbsp;{otpCountdown + 's'}</span>}
						</div>
					</div>
					<div className='login-btn-container multi-button-container'>
						<button ref={otpContinueButtonRef} className='form-btn btn-rounded' onClick={handleLoginOTP}>Continue <span className='chevron-r'/></button>
						<button className='form-btn cancel btn-rounded secondary' onClick={handleCancel}>Cancel</button>						
					</div>
				</>)}

				{isAccountLocked && (
					<button className='form-btn btn-rounded' onClick={handleCancel}>Return to Log In</button>
		    )}
				
				{ spinner && <SpinnerOverlay message='Validating your credentials...'/> }
			</> }		
		</div>
	);
};

const useFocus = () =>{
	const htmlRef = useRef(null);
	const setFocus = () => { htmlRef.current && htmlRef.current.focus() }
	return [htmlRef, setFocus];
}

// Exports
export default Login;
