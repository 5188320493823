import React from "react";

const OTPField = ({index, handleOTPChange, handleOTPKeyDown, handleOTPKeyUp, reference, ariaLabel}) => {
    return (
        <input ref={reference} 
            inputMode='numeric' 
            className='otp-field dd-privacy-mask' 
            type='text' 
            maxLength='1' 
            // placeholder="-" removed placeholder so screen reader will say "blank" instead of "hyphen"
            aria-label={ariaLabel}
            pattern="\b\d\b" 
            onChange={handleOTPChange(index)} 
            onKeyDown={handleOTPKeyDown(index)} 
            onKeyUp={handleOTPKeyUp} 
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            required />
    )
}


export default OTPField;